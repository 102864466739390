import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Values() {
  return (
    <section className="values ps-2 pe-2" id="valeurs">
      <Container className="h-100 pt-4 pb-4">
        <Row className="align-items-center pt-5 pb-5">
          <Col
            xs={12}
            className="values-top d-flex align-items-center justify-content-center mb-4"
          >
            <h4>NOS VALEURS</h4>
          </Col>
          <Col xs={12} lg={6} className="values-left mb-5 mb-lg-0">
            <div className="d-flex align-items-center">
              <div className="rounded-5 p-2 me-2 d-flex align-items-center justify-content-center">
                <i class="bi bi-check-lg  d-flex align-items-center justify-content-center"></i>
              </div>
              <h2 className="m-0">Excellence</h2>
            </div>
            <div className="d-flex align-items-center">
              <div className="rounded-5 p-2 me-2 d-flex align-items-center justify-content-center">
                <i class="bi bi-check-lg  d-flex align-items-center justify-content-center"></i>
              </div>
              <h2 className="m-0">Accessibilité</h2>
            </div>
            <div className="d-flex align-items-center">
              <div className="rounded-5 p-2 me-2 d-flex align-items-center justify-content-center">
                <i class="bi bi-check-lg  d-flex align-items-center justify-content-center"></i>
              </div>
              <h2 className="m-0">Croissance professionnelle</h2>
            </div>
            <div style={{ width: "97%", marginTop: "20px" }}>
              <p>
                Ces 3 valeurs fondamentales guident toutes nos actions et
                initiatives chez <b>Xcel Metor</b>, reflétant notre engagement à
                transformer la vie professionnelle de nos apprenants grâce à une
                maîtrise complète d'Excel.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={6} className="values-right">
            <Row>
              <Col xs={12} md={6} className="mb-5 mb-md-0">
                <div className="rounded-3 card-vision">
                  <div className="rounded-3">
                    <i class="bi bi-lightbulb-fill"></i>
                  </div>
                  <h5>NOTRE VISION</h5>
                  <p className="m-0">
                    <b>Xcel Metor</b> aspire à devenir le <b>choix numéro 1</b>{" "}
                    pour la formation à <b>travers le Maroc</b>, en offrant des
                    programmes adaptés aux besoins spécifiques des étudiants et
                    des professionnels.
                  </p>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="rounded-3 card-mission">
                  <div className="rounded-3">
                    <i class="bi bi-briefcase-fill"></i>
                  </div>
                  <h5>NOTRE MISSION</h5>
                  <p className="m-0">
                    <b>Xcel Metor</b> est <b>votre partenaire privilégié</b> qui
                    vous arme des compétences essentielles pour{" "}
                    <b>se démarquer</b> sur le marché du travail.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
