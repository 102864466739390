import About from './components/About';
import AreYouReady from './components/AreYouReady';
import Banner from './components/Banner';
import ContactUs from './components/ContactUs';
import { Footer } from './components/Footer';
import { NavBar } from './components/NavBar';
import Projects from './components/Projects';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Values from './components/Values';

function App() {
  return (
    <div>
      <NavBar />
      <Banner />
      <About />
      <Values />
      <Services />
      <Testimonials />
      <AreYouReady />
      <Projects />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
