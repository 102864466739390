import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function AreYouReady() {
  return (
    <section className="areYouReady ps-2 pe-2" id="etesvouspret">
      <Container className="h-100 pt-4 pb-4 d-flex flex-column justify-content-evenly">
        <Row className="align-items-center pt-5 pb-5">
          <Col
            xs={12}
            className="areYouReady-top text-center d-flex flex-column align-items-center justify-content-center"
          >
            <h4 className="title">ÊTES-VOUS PRÊT ?</h4>
            <h1 className="title">Boostez votre Carrière</h1>
            <h1 className="title">Exceller avec Xcel Metor !</h1>
            <a
              className="now p-3 text-decoration-none mt-4"
              href="#contactez-nous"
            >
              Inscrivez-vous maintenant{" "}
              <i
                class="bi bi-arrow-right-circle ml-1"
                style={{ fontSize: "20px" }}
              ></i>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
