import { Navbar, Container, Nav } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import logo from '../assets/img/XcelMetor-Logo.png';

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('acceuil');
  const [scrolled, seScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        seScrolled(true);
      } else {
        seScrolled(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Navbar
      expand="lg"
      className={scrolled ? 'scrolled pt-2 pb-2' : 'pt-4 pb-2'}
    >
      <Container>
        <Navbar.Brand href="#acceuil" className="">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              href="#acceuil"
              className={
                activeLink === 'acceuil'
                  ? 'active navbar-link text-center'
                  : 'navbar-link text-center'
              }
              onClick={() => onUpdateActiveLink('acceuil')}
            >
              Accueil
            </Nav.Link>
            <Nav.Link
              href="#apropos"
              className={
                activeLink === 'apropos'
                  ? 'active navbar-link text-center'
                  : 'navbar-link text-center'
              }
              onClick={() => onUpdateActiveLink('apropos')}
            >
              À propos
            </Nav.Link>
            <Nav.Link
              href="#services"
              className={
                activeLink === 'services'
                  ? 'active navbar-link text-center'
                  : 'navbar-link text-center'
              }
              onClick={() => onUpdateActiveLink('services')}
            >
              Services
            </Nav.Link>
            <Nav.Link
              href="#projets"
              className={
                activeLink === 'projetsJ'
                  ? 'active navbar-link text-center'
                  : 'navbar-link text-center'
              }
              onClick={() => onUpdateActiveLink('projets')}
            >
              Projets
            </Nav.Link>
            <Nav.Link
              href="#contactez-nous"
              className={
                activeLink === 'contactez-nous'
                  ? 'active navbar-link text-center contactez-nous-link p-2 pe-3 ps-3 rounded-5'
                  : 'navbar-link text-center contactez-nous-link p-2 pe-3 ps-3 rounded-5'
              }
              onClick={() => onUpdateActiveLink('contactez-nous')}
            >
              Contactez-nous !
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
