import React, { useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import logo from "../assets/img/XcelMetor-Logo.png";

export const Footer = () => {
  const [email, setEmail] = useState("");
  const form = useRef();

  const joinNewsLetter = (e) => {
    e.preventDefault();
  };
  return (
    <footer className="footer">
      <Container className="p-5">
        <Row>
          <Col
            xs={12}
            md={3}
            className="d-flex flex-column justify-content-center justify-content-md-start  mb-3 mb-md-0"
          >
            <img src={logo} alt="Logo" />
            <p className="mb-2 mt-4">
              <i class="bi bi-signpost-fill"></i> Casablanca, Maroc
            </p>
            <p className="mb-2">
              <i class="bi bi-telephone-forward-fill"></i> +212 7 06 28 64 34
            </p>
            <p className="mb-2">
              <i class="bi bi-envelope-at-fill"></i> contact@xcelmetor.com
            </p>
          </Col>
          <Col xs={12} md={2} className="d-flex flex-column mb-3 mb-md-0">
            <p>Accueil</p>
            <a href="#apropos">À propos</a>
            <a href="#apropos">Nos valeurs</a>
            <a href="#services">Services</a>
          </Col>
          <Col xs={12} md={2} className="d-flex flex-column mb-3 mb-md-0">
            <p>Projects</p>
            <a href="#apropos">Formation</a>
            <a href="#apropos">Cas pratique</a>
            <a href="#apropos">Témoignages</a>
          </Col>
          <Col xs={12} md={2} className="d-flex flex-column mb-3 mb-md-0">
            <p>Contact</p>
            <a href="https://api.whatsapp.com/send?phone=+212706286434&text=Bonjour%20je%20suis%20intéressé(e)%20par%20la%20formation!" target="_blank">Whatsapp</a>
            <a href="#instagram">Instagram</a>
            <a href="#linkedin">Facebook</a>
          </Col>
          <Col xs={12} md={3} className="d-flex flex-column mb-3 mb-md-0">
            <h5 className="mb-3">Rejoignez notre newsletter</h5>
            <Form ref={form} onSubmit={joinNewsLetter}>
              <Col sm={12} className="d-flex">
                <input
                  required
                  value={email}
                  type="email"
                  name="user_email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit">Rejoindre</button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
