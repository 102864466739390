import React from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';

export default function Projects() {
  return (
    <section className="projects ps-2 pe-2" id="projets">
      <Container className="h-100 pt-4 pb-4">
        <Row className="align-items-center pt-5">
          <Col
            xs={12}
            className="projects-top d-flex align-items-center justify-content-center"
          >
            <h4>PROJETS</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Carousel >
              <Carousel.Item>
                <div className="videoContainer p-2  d-flex align-items-center justify-content-center">
                  <div className="rounded-3 fs-1 d-flex align-items-center justify-content-center pe-5 ps-5 bg-light">
                    <i class="bi bi-play-circle-fill"></i>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="videoContainer p-2  d-flex align-items-center justify-content-center">
                  <div className="rounded-3 fs-1 d-flex align-items-center justify-content-center pe-5 ps-5 bg-light">
                    <i class="bi bi-play-circle-fill"></i>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
