import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Services() {
  return (
    <section className="services ps-2 pe-2" id="services">
      <Container className="h-100 pt-4 pb-4">
        <Row className="align-items-center pt-5 pb-5">
          <Col
            xs={12}
            className="services-top d-flex align-items-center justify-content-center mb-4"
          >
            <h4>NOS SERVICES</h4>
          </Col>
          <Col xs={12} lg={6} className="services-left mb-5 mb-lg-0">
            <h2>Xceller votre carrière !</h2>
            <p>
              Nous sommes ravis de vous présenter notre formation en mode
              présentiel spécialement conçue pour les débutants en Excel. Si
              vous souhaitez acquérir une compréhension solide de cet outil
              essentiel ou si vous vous sentez un peu intimidé(e) par ses
              fonctionnalités.
              <br />
            </p>
            <div className="test">
              <b>
                <u>Cette formation est parfaite pour vous.</u>
              </b>
            </div>
            <Row>
              <Col xs={12} className="d-flex align-items-center mb-2">
                <div className="rounded-3 me-4 services-parts-icon">
                  <i class="bi bi-people-fill"></i>
                </div>
                <div className="services-parts-text">
                  <h5>Formation en présentiel</h5>
                  <p>
                    Explorez notre formation en présentiel sur Excel, conçue
                    avec une approche professionnelle exclusive.
                  </p>
                </div>
              </Col>
              <Col xs={12} className="d-flex align-items-center mb-2">
                <div className="rounded-3 me-4 services-parts-icon">
                  <i class="bi bi-file-earmark-spreadsheet-fill"></i>
                </div>
                <div className="services-parts-text">
                  <h5>Cas pratique</h5>
                  <p>
                    Bénéficiez de projets pratiques en Gestion Fin, en C. de
                    Gestion, en management des RH, et bien plus encore.
                  </p>
                </div>
              </Col>
              <Col xs={12} className="d-flex align-items-center mb-2">
                <div className="rounded-3 me-4 services-parts-icon">
                  <i class="bi bi-award-fill"></i>
                </div>
                <div className="services-parts-text">
                  <h5>Double certification</h5>
                  <p>
                    Obtenez 1 certification attestant de votre maîtrise d'Excel,
                    ainsi qu'une deuxième certification délivrée par LinkedIn.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
