import React, { useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ContactUs() {
  const formInitialDetails = {
    fullName: "",
    email: "",
    phone: "",
    message: "",
  };

  const [formDetails, setFormDetails] = useState(formInitialDetails);

  const emailAdress = "Contact@xcelmetor.com";

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const form = useRef();

  const sendSuccess = () =>
    toast.success("Message envoyé", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });

  const sendError = () =>
    toast.error("Message envoyé", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_ez15k0y", "template_vkb4696", form.current, {
        publicKey: "8OCNF0F6zHVjkscjo",
      })
      .then(
        () => {
          sendSuccess();
          form.current.reset();
        },
        (error) => {
          sendError();
        }
      );
  };
  return (
    <section className="contactUs ps-2 pe-2" id="contactez-nous">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <Container className="h-100 pt-4 pb-4">
        <Row className="align-items-start pt-5 pb-5">
          <Col xs={12} lg={6} className="contactUs-left mb-5 mb-lg-0">
            <h2>Nous sommes toujours impatients de vous entendre !</h2>

            <div className="info">
              <div className="location">
                <i
                  style={{ fontSize: "25px" }}
                  class="bi bi-signpost-fill pl-1"
                ></i>{" "}
                <p>Casablanca, Maroc</p>
              </div>

              <div className="location">
                <i
                  style={{ fontSize: "25px" }}
                  class="bi bi-envelope-at-fill pl-1"
                ></i>
                <p>{emailAdress}</p>
              </div>

              {/* <div className="location">
                <i
                  style={{ fontSize: "25px" }}
                  class="bi bi-signpost-fill pl-1"
                ></i>{" "}
                <p>Casablanca, Maroc</p>
              </div> */}

              <div className="location">
                <i
                  style={{ fontSize: "25px" }}
                  class="bi bi-telephone-forward-fill"
                ></i>
                <p>+212 7 06 28 64 34</p>
              </div>
            </div>

            <Row>
              <Col className="mt-3">
                <a href="https://api.whatsapp.com/send?phone=+212706286434&text=Bonjour%20je%20suis%20intéressé(e)%20par%20la%20formation!">
                  <i class="bi bi-whatsapp"></i>
                </a>
                <a href="#test">
                  <i class="bi bi-youtube"></i>
                </a>
                <a href="#test">
                  <i class="bi bi-instagram"></i>
                </a>
                <a href="#test">
                  <i class="bi bi-facebook"></i>
                </a>
                <a href="#test">
                  <i class="bi bi-tiktok"></i>
                </a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <Form className="rounded" ref={form} onSubmit={sendEmail}>
              <h4 className="mb-3" style={{ letterSpacing: "2px" }}>
                Contactez-nous
              </h4>
              <div className="mb-4">
                <p style={{ fontSize: "15px" }}>
                  Complétez le formulaire pour planifier une consultation
                  personnalisée avec l'un de nos experts.
                </p>
              </div>
              <Row>
                <Col sm={12} className="px-1">
                  <input
                    required
                    value={formDetails.fullName}
                    type="text"
                    name="user_fullName"
                    placeholder="Nom complet"
                    onChange={(e) => onFormUpdate("fullName", e.target.value)}
                  />
                </Col>
                <Col sm={12} className="px-1">
                  <input
                    required
                    value={formDetails.email}
                    type="email"
                    name="user_email"
                    placeholder="Email"
                    onChange={(e) => onFormUpdate("email", e.target.value)}
                  />
                </Col>
                <Col sm={12} className="px-1">
                  <input
                    required
                    value={formDetails.phone}
                    type="tel"
                    name="user_phone"
                    placeholder="Num tél"
                    onChange={(e) => onFormUpdate("phone", e.target.value)}
                  />
                </Col>
                <Col className="px-1">
                  <textarea
                    required
                    value={formDetails.message}
                    row="6"
                    name="message"
                    placeholder="Message"
                    onChange={(e) => onFormUpdate("message", e.target.value)}
                  ></textarea>
                  <button type="submit" className="p-2 pe-3 ps-3 rounded-5">
                    Envoyer <i class="ms-2 bi bi-arrow-right"></i>
                  </button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
