import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function About() {
  return (
    <section className="about ps-2 pe-2" id="apropos">
      <Container className="pt-4 pb-4">
        <Row className="align-items-center pt-5 pb-5">
          <Col xs={12} lg={6} className="ps-5 pe-5 about-left mb-5 mb-lg-0">
            <div className="rounded-3 fs-1 d-flex align-items-center justify-content-center p-5 bg-light">
              <i class="bi bi-play-circle-fill"></i>
            </div>
          </Col>
          <Col xs={12} lg={6} className="about-right">
            <h4>À PROPOS</h4>
            <p>
              Chez <b>Xcel Metor</b>, nous sommes dédiés à former
              <b> la prochaine génération de professionnels</b> en leur offrant
              une formation <b>certifiante</b> en Excel, spécialement conçue
              pour les débutants.
            </p>
            <p>
              Notre mission est de
              <b> rendre les compétences en Excel accessibles</b> à tous, que
              vous cherchiez à <b>améliorer vos compétences</b> pour booster
              votre carrière ou à <b>acquérir une nouvelle expertise</b>.
            </p>
            <div className="d-flex divStyle">
              <div />
              <p className="m-0">
                <b>
                  Xcel Metor est là pour vous accompagner à chaque étape de
                  votre apprentissage.
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
