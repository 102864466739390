import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import imgAmine from "../assets/img/amine2.png";
import { motion } from "framer-motion";

const Banner = () => {
  return (
    <section className="banner ps-2 pe-2" id="acceuil">
      <Container>
        <Row className="align-items-center pt-4 pt-lg-0">
          <Col xs={12} lg={6} className="banner-left mb-4 mb-lg-0">
            <motion.h1
              initial={{ x: 0, y: 100, opacity: 0 }}
              transition={{ duration: 0.5, ease: "linear" }}
              animate={{ x: 0, y: 0, opacity: 1 }}
            >
              Maîtrisez Excel, Boostez votre Carrière
            </motion.h1>
            <motion.p
              initial={{ x: 0, y: 100, opacity: 0 }}
              transition={{ duration: 1, ease: "linear" }}
              animate={{ x: 0, y: 0, opacity: 1 }}
            >
              Exceller avec <b>Xcel Metor</b>, boostez votre carrière grâce à
              notre formation certifiante spécialement conçue pour les
              débutants.
            </motion.p>
            <a className="text-decoration-none" href="#contactez-nous">
              <motion.button
                className="p-2 pe-3 ps-3 rounded-5"
                initial={{ x: 0, y: 100, opacity: 0 }}
                transition={{ duration: 1, ease: "linear" }}
                animate={{ x: 0, y: 0, opacity: 1 }}
              >
                Inscrivez-vous dès aujourd’hui !
              </motion.button>
            </a>
          </Col>
          <Col xs={12} lg={6} className="banner-right">
            <motion.img
              src={imgAmine}
              alt="amine"
              initial={{ x: 0, opacity: 0 }}
              transition={{ delay: 0.5, duration: 1, ease: "linear" }}
              animate={{ x: 100, opacity: 1 }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
