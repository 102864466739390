import React from "react";
import { Card, Carousel, Col, Container, Row } from "react-bootstrap";
import imgprofile1 from "../assets/img/profile.jpg";
import imgprofile2 from "../assets/img/profile2.jpg";
import imgprofile3 from "../assets/img/profile3.jpg";

export default function Testimonials() {
  return (
    <section className="testimonials ps-2 pe-2" id="temoignages">
      <Container className="h-100 pt-4 pb-4 d-flex flex-column justify-content-evenly">
        <Row className="align-items-center pt-5 pb-5 mb-3">
          <Col
            xs={12}
            className="testimonials-top text-center d-flex flex-column align-items-center justify-content-center mb-4"
          >
            <h4>TÉMOIGNAGES</h4>
            <h2>LISEZ CE QUE LES AUTRES ONT À DIRE</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Carousel>
              <Carousel.Item>
                <Card className="testimonials-card">
                  <Card.Img variant="top" src={imgprofile1} />
                  <Card.Body className="text-center">
                    <Card.Title><b><i>Brahim A.</i></b></Card.Title>
                    <Card.Text>
                      La formation <b>" Excel pour les professionnels "</b> de <b> Xcel Metor </b>m'a permis de <b>maîtriser des fonctionnalités complexes </b>
                      pour mieux gérer mes projets. Les astuces partagées ont <b>transformé ma façon de travailler.</b>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item>
                <Card className="testimonials-card">
                  <Card.Img variant="top" src={imgprofile2} />
                  <Card.Body className="text-center">
                    <Card.Title><b><i>Ilyass E.</i></b></Card.Title>
                    <Card.Text>
                      La formation <b>" Excel pour les professionnels "</b> de <b>Xcel Metor</b> m'a permis de surmonter mes appréhensions en tant
                      que débutant. <b>Les cours étaient clairs et pratiques </b>, et j'utilise maintenant Excel avec confiance dans mon travail quotidien
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item>
                <Card className="testimonials-card">
                  <Card.Img variant="top" src={imgprofile3} />
                  <Card.Body className="text-center">
                    <Card.Title><b><i>Mohamed Amine E.</i></b></Card.Title>
                    <Card.Text>
                      Grâce à la formation <b>" Excel pour les professionnels "</b> de <b>Xcel Metor</b>, j'ai considérablement <b>amélioré mon efficacité</b>
                      au travail et obtenu une promotion. <b>Les exemples concrets et les explications claires</b> m'ont beaucoup aidé.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
